.color-primary {
  color: #1da1f2;
}

.waitlist-title {
  width: 80%;
  text-align: center;
  font-size: 2.5rem;
  font-weight: normal;
  margin-bottom: 80px;
  margin: auto;
}

@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}
