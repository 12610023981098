body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* .video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
} */
.video {
  width: 100%;
  height: 50%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 100;
}

.video video {
  width: 100%;
  height: auto;
}
.video-link-cont {
  margin-top: 20px;
}
.video-link-cont,
.video-link-cont a {
  color: white;
  color: #1da1f2;
}
.video-link-cont a {
  text-decoration: none;
}
